import styled from 'styled-components';
import { rem } from 'polished';
import IconTooltip from '@molecules/IconTooltip/IconTooltip';
import variables from '@styles/variables';

export const StyledProductTableOfInformationSection = styled.div`
  margin-bottom: ${rem(15)};

  table {
    thead {
      td:first-child {
        padding-left: 0;
      }
    }
  }
`;

export const StyledProductTableOfInformationCountryOrigin = styled(IconTooltip)`
  display: inline-flex;
  margin-left: ${rem(5)};
`;

export const StyledHtmlElement = styled.div`
  p {
    font-family: ${variables.fonts.fontGothamBook};
    font-size: ${rem(15)};
    line-height: ${rem(24)};
  }
`;
