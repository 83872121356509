import useAbortController from '@hooks/useAbortController';
import { selectDeliveryPicker, setDeliveryPickerIsOpen } from '@slices/deliveryPickerSlice';
import { trackDeliveryConfirmedTime } from '@helpers/analyticsHelpers/trackDeliveryWidget';
import UserAgent from '@helpers/useragent';
import webToAppApi from '@api/web-to-app';
import type { AddressData, AddToCartForm, AxfoodCartProductViewModel, AxfoodCartViewModel } from '@occ/api-client';
import type { AxfoodStoreInfoViewModel } from '@occ/api-client';
import getPickUnit from '@helpers/getPickUnit';
import {
  addToCart,
  setCartContactInfo,
  clearCart,
  setSlotInCart,
  putOrderInCart,
  replaceEko,
  replaceAll,
} from '@api/interfaces/cartApi';
import { setDiscountTooltip, setInfoTooltip } from '@slices/miniCartSlice';
import trackCartModification from '@helpers/analyticsHelpers/trackCartModification';
import trackChangeOrderModal from '@helpers/analyticsHelpers/trackChangeOrderModal';
import { setChangeOrderModalVisibility } from '@slices/modalSlice';
import paths from '@constants/paths';
import trackRemoveFromCart from '@helpers/analyticsHelpers/trackRemoveFromCart';
import useCart from '@hooks/useCart';
import { useAppDispatch, useAppSelector } from '@hooks/useAppDispatch';
import useCustomRouter from '@hooks/useCustomRouter';
import { setIsUpdatingCart } from '@slices/cartUpdatingSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import config from '@config';
import useCheckCartConflict from '@hooks/useCheckCartConflict';

type ReplaceableProduct = { [key: string]: boolean };
const getReplaceableProducts = (products: AxfoodCartProductViewModel[]): ReplaceableProduct => {
  return products.reduce<ReplaceableProduct>((acc, current) => {
    // eslint-disable-next-line no-param-reassign
    if (current.canBeReplaced) {
      acc[current.code] = current.replacement;
    }
    return acc;
  }, {});
};
const makeProductWithNoReplacementFlag = (allProducts: AxfoodCartProductViewModel[], product: AddToCartForm) => {
  const prevReplaceableProducts = getReplaceableProducts(allProducts); // [product,...] -> [{code: replacement},...]
  const numberOfReplacementKeys = Object.keys(prevReplaceableProducts).filter((replacement: string) => {
    return prevReplaceableProducts[replacement];
  }).length;
  const isReplaceSome = Object.keys(prevReplaceableProducts).length > numberOfReplacementKeys;

  // product: CartProduct => {...product, noReplacementFlag: true|false}
  return {
    ...product,
    noReplacementFlag:
      prevReplaceableProducts[product.productCodePost] !== undefined
        ? !prevReplaceableProducts[product.productCodePost]
        : isReplaceSome,
  };
};

const useCartActions = () => {
  const dispatch = useAppDispatch();
  const router = useCustomRouter();
  const deliveryPicker = useAppSelector(selectDeliveryPicker);
  const { cart, isCartEmpty, refreshCart } = useCart();
  const getSignal = useAbortController();
  const checkCartConflict = useCheckCartConflict({ checkStock: false });
  const [deliveryModeReminderDone, setDeliveryModeReminderDone] = useLocalStorage(
    config.LOCAL_STORAGE_SELECTORS.deliveryModeReminderDone,
    false
  );

  const updateSlotInCart = async (
    slot: string,
    isTmsSlot: boolean,
    tmsDeliveryWindowReference: any,
    homeDelivery: boolean,
    isCheckout: boolean
  ) => {
    try {
      const signal = getSignal();
      dispatch(setIsUpdatingCart(true));
      await checkCartConflict({ slotCode: slot });
      const cartResp = await setSlotInCart(slot, isTmsSlot, tmsDeliveryWindowReference, signal);

      await refreshCart(cartResp.data);
      dispatch(setDeliveryPickerIsOpen({ isOpen: false, proceedToCheckout: deliveryPicker.proceedToCheckout }));
      trackDeliveryConfirmedTime(homeDelivery, isCheckout);
      dispatch(setIsUpdatingCart(false));
      return Promise.resolve(cartResp);
    } catch (e) {
      dispatch(setIsUpdatingCart(false));
      return Promise.reject(e);
    }
  };

  const addOrderToCart = async (orderID: string) => {
    dispatch(setIsUpdatingCart(true));
    const cart = await putOrderInCart(orderID);

    if (UserAgent.isNativeApp()) {
      webToAppApi.actionShowUpdatedCartAfterAddingOrderToCart(cart.data.totalUnitCount);
    }

    await refreshCart(cart.data);

    dispatch(setIsUpdatingCart(false));
  };

  const updateCartContactInfo = async (contactInfo: AddressData) => {
    try {
      dispatch(setIsUpdatingCart(true));
      const cartResp = await setCartContactInfo(contactInfo);

      await refreshCart(cartResp);
      dispatch(setIsUpdatingCart(false));
      return Promise.resolve(cartResp);
    } catch (e) {
      dispatch(setIsUpdatingCart(false));
      return Promise.reject(e);
    }
  };

  const postToCart = async (
    products: Array<{
      product: AxfoodCartProductViewModel;
      newQuantity: number;
      prevQuantity: number;
      listName: string;
    }>,
    store?: AxfoodStoreInfoViewModel
  ) => {
    dispatch(setIsUpdatingCart(true));

    try {
      // TODO: Move to pre-processing
      const activelySelected = !!store?.activelySelected;
      const cartProducts: AddToCartForm[] = products.map(({ product, newQuantity }) => {
        const lowestHistoricalPrice = product?.potentialPromotions
          ? product?.potentialPromotions[0]?.lowestHistoricalPrice
          : null;
        const hideDiscountToolTip = !!lowestHistoricalPrice && lowestHistoricalPrice?.value !== product.priceValue;

        return makeProductWithNoReplacementFlag(cart?.products || [], {
          productCodePost: product.code,
          qty: newQuantity,
          pickUnit: getPickUnit(product?.productBasketType?.code),
          hideDiscountToolTip,
        });
      });

      const wasEmpty = Boolean(isCartEmpty);

      const data = await addToCart(cartProducts);

      // PIL Discount value
      if (data.discountToolTip && data.discountToolTip > 0) {
        dispatch(setDiscountTooltip({ value: data.discountToolTip, show: true }));
      }

      await refreshCart(data);
      dispatch(setIsUpdatingCart(false));

      // TODO: Move to post-processing
      products.forEach(({ product, newQuantity, prevQuantity, listName }) => {
        if (product.addToCartMessage && newQuantity > prevQuantity && prevQuantity === 0) {
          dispatch(setInfoTooltip({ text: product.addToCartMessage, show: true }));
        } else if (product.nicotineMedicalProduct && newQuantity > prevQuantity) {
          dispatch(setInfoTooltip({ text: 'nicotine', show: true }));
        } else if (product.tobaccoProduct && newQuantity > prevQuantity) {
          dispatch(setInfoTooltip({ text: 'tobacco', show: true }));
        }

        trackCartModification(newQuantity, prevQuantity, product, listName, data.code, wasEmpty);
      });

      const shouldDispatchDeliveryPicker =
        data?.products?.length === 1 && !activelySelected && !deliveryModeReminderDone;
      if (shouldDispatchDeliveryPicker) {
        dispatch(setDeliveryPickerIsOpen({ isOpen: true }));
        setDeliveryModeReminderDone(true);
      }

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const cancelChangeOrder = async (cart?: AxfoodCartViewModel) => {
    trackChangeOrderModal('cancel_change_order_modal', 'confirm');
    const newCart = await clearCart(true);
    await refreshCart(newCart.data);
    dispatch(setChangeOrderModalVisibility(false));
    if (UserAgent.isNativeApp()) {
      webToAppApi.eventCancelOrderUpdate(0);
    } else {
      router.replace(
        { pathname: paths.ACCOUNT_ORDER_DETAILS, query: { ...router.query } },
        { pathname: `${paths.ACCOUNT_ORDERS}/${cart?.orderReference}` }
      );
    }
    trackRemoveFromCart(cart?.products ?? [], 'cancel_change_order', cart?.code || '');
  };

  const postReplaceEko = async (replace: boolean) => {
    dispatch(setIsUpdatingCart(true));
    try {
      const resp = await replaceEko(replace);
      dispatch(setIsUpdatingCart(false));
      await refreshCart(resp.data);
      return Promise.resolve(resp);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const postReplaceAll = async (replace: boolean[]) => {
    dispatch(setIsUpdatingCart(true));
    try {
      const resp = await replaceAll(replace);
      dispatch(setIsUpdatingCart(false));
      await refreshCart(resp);
      return Promise.resolve(resp);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return {
    addOrderToCart,
    cancelChangeOrder,
    postReplaceAll,
    postReplaceEko,
    postToCart,
    updateCartContactInfo,
    updateSlotInCart,
  };
};

export default useCartActions;
