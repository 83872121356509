import { StyledTable } from '@atoms/Table/Table.styles';
import { ReactElement } from 'react';

export type TableProps = {
  children: ReactElement[];
  className?: string;
};

const Table = ({ children, className }: TableProps) => {
  return <StyledTable className={className}>{children}</StyledTable>;
};

export default Table;
