import styled, { css } from 'styled-components';
import { rem, rgba } from 'polished';
import variables from '@styles/variables';

export const StyledLink = styled.a<{ variant?: LinkVariant }>`
  font-family: ${variables.fonts.fontGothamMedium};
  text-decoration: none;
  font-size: inherit;

  ${(props) =>
    props.variant === 'primary' &&
    css`
      color: ${variables.colors.highlight};
      &:visited {
        color: ${variables.colors.highlight};
      }
      &:hover {
        color: ${variables.colors.highlightHover};
      }
    `}

  ${(props) =>
    props.variant === 'secondary' &&
    css`
      color: ${variables.colors.white};
      &:visited {
        color: ${variables.colors.white};
      }
      &:hover {
        color: ${rgba(variables.colors.white, 0.7)};
      }
    `}
  
    ${(props) =>
    props.variant === 'nav' &&
    css`
      color: ${variables.colors.lighterBlack};
      &:visited {
        color: ${variables.colors.lighterBlack};
      }
      &:hover {
        color: ${variables.colors.primary};
      }
    `}
  
    ${(props) =>
    props.variant === 'sidenav' &&
    css`
      color: ${variables.colors.lighterBlack};
      font-family: ${variables.fonts.fontGothamBook};
      font-size: ${rem(13)};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      &:visited {
        color: ${variables.colors.lighterBlack};
      }
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: ${variables.colors.primary};
        }
      }
    `}
  
    ${(props) =>
    props.variant === 'usp' &&
    css`
      color: ${variables.colors.darkerGray};
      font-family: ${variables.fonts.fontGothamBook};
      font-size: ${rem(13)};
      &:hover,
      &:visited {
        color: ${variables.colors.darkerGray};
      }
    `};

  ${(props) =>
    props.variant === 'black' &&
    css`
      color: ${variables.colors.lighterBlack};
      &:visited {
        color: ${variables.colors.lighterBlack};
      }
      &:hover {
        color: ${rgba(variables.colors.lighterBlack, 0.8)};
      }
    `}

  ${(props) =>
    props.variant === 'darkGrey' &&
    css`
      color: ${variables.colors.darkerGray};
      &:visited {
        color: ${variables.colors.darkerGray};
      }
      &:hover {
        color: ${rgba(variables.colors.darkerGray, 0.5)};
      }
    `}
    
    ${(props) =>
    props.variant === 'white' &&
    css`
      color: ${variables.colors.white};
      &:visited {
        color: ${variables.colors.white};
      }
      &:hover {
        color: ${rgba(variables.colors.white, 0.8)};
      }
    `}
`;
