import styled from 'styled-components';
import { rem } from 'polished';
import { H1Styles, H2Styles, H3Styles } from '@atoms/Heading/Heading.styles';
import Link from '@atoms/Link/Link';
import { imageStyling, textStyling } from '@organisms/CmsComponent/CmsComponent.styles';

export const StyledNewsComponentDetail = styled.div`
  &:first-child {
    margin-top: ${rem(40)};
  }

  h1 {
    ${H1Styles()}
  }

  h2 {
    ${H2Styles()}
  }

  h3 {
    ${H3Styles()}
  }

  ${imageStyling};
  ${textStyling};
`;

export const StyledWrapper = styled.div`
  text-align: left;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: ${rem(10)};
`;

export const StyledLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};
  margin-bottom: ${rem(40)};
`;
