import { AxfoodApi, AddToCartForm, CustomerContactForm, AddressData } from '@occ/api-client';

const axfoodApi = new AxfoodApi({
  baseUrl: '',
});

export const restoreCartAndCloseOpenOrders = (orderID: string) =>
  axfoodApi.restoreCartAndCloseOpenOrdersUsingPost({ action: orderID });

export const restoreCart = (action: string) => axfoodApi.restoreCartUsingPost({ action });

export const addToCart = async (products: AddToCartForm[], storeId?: string) => {
  const response = await axfoodApi.addToCartUsingPost({
    products,
    storeId,
  });
  return response.data;
};

export const getCartStatus = (slotCode?: string, storeId?: string, checkStock?: boolean, signal?: AbortSignal) => {
  return axfoodApi.checkCartStatusUsingGet(
    {
      slotCode,
      storeId,
      checkStock,
    },
    { signal }
  );
};

export const clearCart = (isReplacingOrder = false) =>
  axfoodApi.clearCartUsingDelete({
    cancelPossibleContinueCart: isReplacingOrder,
  });

export const setDeliveryMode = (
  deliveryModeCode: 'homeDelivery' | 'pickUpInStore',
  newSuggestedStoreId: string,
  signal?: AbortSignal
) => axfoodApi.setDeliveryModeUsingPost(deliveryModeCode, { newSuggestedStoreId }, { signal });

export const getPersistedCart = async () => {
  const response = await axfoodApi.getMergablePersistentCartUsingGet();
  return response.data;
};

export const getCart = async () => {
  const response = await axfoodApi.getCartUsingGet();
  return response.data;
};

export const setCartContactInfo = async (contactInfo: AddressData) => {
  const response = await axfoodApi.setCustomerContactUsingPost(contactInfo as CustomerContactForm);
  return response.data;
};

export const setSlotInCart = (slot: string, isTmsSlot: boolean, tmsDeliveryWindowReference: any, signal: AbortSignal) =>
  axfoodApi.setSlotToCartUsingPost(slot, tmsDeliveryWindowReference, { isTmsSlot }, { signal });

export const deleteSlotInCart = (signal?: AbortSignal) => axfoodApi.removeSlotFromCartUsingDelete({ signal });

export const getMostLikelyCartAddress = (signal: AbortSignal) =>
  axfoodApi.getCustomerLikelyDeliveryAddressUsingGet({ signal });

export const putOrderInCart = (orderID: string) => axfoodApi.putToCartUsingPost1(orderID);

export type CartAddressType = {
  addressLine: string;
  addressLine2: string;
  postalCode: string;
  locality: string;
  longitude: number;
  latitude: number;
  firstName: string;
  lastName: string;
  email?: string;
  cellphone: string;
  companyName?: string;
};

export const setAddressOnCart = ({
  addressLine,
  addressLine2,
  cellphone,
  companyName,
  email,
  firstName,
  lastName,
  latitude,
  locality,
  longitude,
  postalCode,
}: CartAddressType) => {
  return axfoodApi.setDeliveryAddressUsingPost({
    firstName,
    lastName,
    addressLine1: addressLine,
    addressLine2,
    postalCode,
    town: locality,
    email: email ? email : undefined,
    companyName: companyName ? companyName : undefined,
    cellphone,
    longitude,
    latitude,
  });
};

export const setPostalCodeOnCart = (postalCode: string) => axfoodApi.setPostalCodeUsingPost({ postalCode });

export const getHomeDeliverySlots = (postalCode: string, b2bCustomer = false, signal: AbortSignal) =>
  axfoodApi.getHomeDeliverySlotsUsingGet({ postalCode, b2b: b2bCustomer }, { signal });

export const getPickUpInStoreSlots = (storeId: string, b2bCustomer = false, signal: AbortSignal) =>
  axfoodApi.getPickInStoreSlotsUsingGet(
    {
      storeId,
      b2b: b2bCustomer,
    },
    { signal }
  );

export const setCustomerExtraInfo = (usersOrderReference: string, doorCode: string, commentFromCustomer: string) =>
  axfoodApi.setCustomerExtraUsingPost({
    commentFromCustomer,
    doorCode,
    usersOrderReference,
  });

export const replaceEko = (replaceEko: boolean) =>
  axfoodApi.updateEkoReplacementFlagUsingPost({
    replaceEko,
  });

export const replaceAll = async (replace: boolean[]) => {
  const data = new FormData();
  replace.forEach((item, i) => data.append(`entries[${i}]`, (!item).toString()));

  const resp = await fetch('/axfood/rest/cart/update-noreplacement', {
    method: 'POST',
    body: data,
  });
  return resp.json();
};
