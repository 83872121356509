import React, { forwardRef, Ref } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { StyledButton, StyledSpinner } from './Button.styles';
import variables from '@styles/variables';

export interface ButtonProps
  extends Pick<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    | 'children'
    | 'disabled'
    | 'onClick'
    | 'onKeyDown'
    | 'tabIndex'
    | 'className'
    | 'type'
    | 'title'
    | 'onKeyUp'
    | 'onFocus'
    | 'onBlur'
  > {
  size?: ButtonSize;
  theme?: ButtonTheme;
  color?: string;
  background?: string;
  borderColor?: string;
  'data-testid'?: string;
  'aria-label'?: string;
  centered?: boolean;
  isSubmitting?: boolean;
  isSelected?: boolean;
}

const Button = forwardRef(
  (
    {
      theme = 'primary',
      disabled = false,
      onClick,
      onKeyDown,
      children,
      size = 'default',
      title,
      type = 'button',
      color,
      background,
      borderColor,
      className,
      centered = false,
      isSubmitting,
      isSelected,
      onFocus,
      onBlur,
      ...props
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const { t } = useTranslation('common');

    const whiteSpinnerThemes: Array<ButtonTheme> = ['primary', 'primary-light-bg'];
    const spinnerColor: keyof typeof variables.colors = whiteSpinnerThemes.includes(theme) ? 'white' : 'black';

    const buttonContent = isSubmitting ? (
      <>
        <StyledSpinner title={t('button->submitting')} color={spinnerColor} size={size?.includes('small') ? 16 : 20} />
        <span>{children}</span>
      </>
    ) : (
      children
    );

    return (
      <StyledButton
        ref={ref}
        data-testid={props['data-testid']}
        className={className}
        theme={theme}
        background={background}
        color={color}
        size={size}
        centered={centered}
        type={type}
        title={title}
        onClick={onClick}
        onKeyDown={onKeyDown}
        disabled={disabled || isSubmitting}
        aria-disabled={disabled || isSubmitting}
        isSubmitting={isSubmitting}
        borderColor={borderColor}
        aria-label={props['aria-label']}
        isSelected={isSelected}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {buttonContent}
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';

export default Button;
