import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import Text from '@atoms/Text/Text';
import Heading from '@atoms/Heading/Heading';
import Button from '@atoms/Button/Button';
import Icon from '@atoms/Icon/Icon';

const responsiveBackgroundImage = (media: responsiveImageMediaType) => {
  return css`
    background-image: url(${media.mobile?.url || media.tablet?.url || media.desktop?.url || media.widescreen?.url});

    ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
      background-image: url(${media.tablet?.url || media.desktop?.url || media.widescreen?.url || media.mobile?.url});
    }

    ${Config.MEDIA.FROM_DESKTOP} {
      background-image: url(${media.desktop?.url || media.widescreen?.url || media.tablet?.url || media.mobile?.url});
    }

    ${Config.MEDIA.FROM_DESKTOP_XLARGE} {
      background-image: url(${media.widescreen?.url || media.desktop?.url || media.tablet?.url || media.mobile?.url});
    }
  `;
};

export const StyledSection = styled.section<{
  fixed: boolean;
  clickable: boolean;
  padding: Padding;
  $media?: responsiveImageMediaType;
}>`
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${rem(380)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    min-height: ${rem(310)};
  }

  ${(props) => props.$media && responsiveBackgroundImage(props.$media)}

  ${(props) =>
    props.padding &&
    props.padding.top &&
    css`
      padding-top: ${props.padding.top}px;
    `}
  ${(props) =>
    props.padding &&
    props.padding.right &&
    css`
      padding-right: ${props.padding.right}px;
    `}
      ${(props) =>
    props.padding &&
    props.padding.bottom &&
    css`
      padding-bottom: ${props.padding.bottom}px;
    `}
        ${(props) =>
    props.padding &&
    props.padding.left &&
    css`
      padding-left: ${props.padding.left}px;
    `}
  ${(props) =>
    props.fixed &&
    css`
      height: ${rem(105)};
      min-height: ${rem(105)};

      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        height: ${rem(312)};
        min-height: ${rem(312)};
      }
    `}

  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}
`;

export const StyledDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: ${rem(35)} ${rem(20)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: ${rem(45)} ${rem(40)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    max-width: ${rem(1048)};
  }
`;

export const StyledHeading = styled(Heading)`
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-width: ${rem(540)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    max-width: ${rem(605)};
  }
`;

export const StyledText = styled(Text)<{ fontType?: string }>`
  margin-top: ${rem(20)};
  font-size: ${rem(15)};
  line-height: ${rem(24)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-width: ${rem(462)};
    font-size: ${(props) => (props.fontType === 'small' ? rem(15) : rem(18))};
    line-height: ${(props) => (props.fontType === 'small' ? rem(24) : rem(28))};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    max-width: ${rem(680)};
  }
`;

export const StyledButton = styled(Button)`
  margin-top: ${rem(20)};

  &:hover {
    background-color: inherit;
  }
`;

export const StyledYoutubeIcon = styled(Icon)`
  margin-right: ${rem(10)};
`;

export const StyledPreamble = styled.span`
  p {
    margin: 0;
  }
`;

export const StyledComponentWrapper = styled.div`
  height: 100%;
`;
