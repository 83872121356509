import { css } from 'styled-components';
import variables from '@styles/variables';
import { ButtonProps } from '@atoms/Button/Button';
import { rem, rgba } from 'polished';

export const ButtonThemesStyles = css<ButtonProps>`
  ${(props) =>
    props.theme === 'primary' &&
    css`
      color: ${variables.colors.white};
      background: ${variables.colors.primary};
      transition: background-color 0.2s linear;
      border-color: transparent;

      &:disabled {
        background: ${variables.colors.primaryInactive};
      }

      &:hover:not(:disabled):not(:is(form:invalid *)) {
        @media (hover: hover) and (pointer: fine) {
          background: ${variables.colors.primaryDark};
        }
      }
    `}

  ${(props) =>
    props.theme === 'primary-light-bg' &&
    css`
      background-color: ${variables.colors.lighterBlack};
      color: white;
      mix-blend-mode: darken;

      ${!props.isSubmitting &&
      css`
        &:disabled {
          opacity: 0.8;
        }
      `}
      &:hover:not(:disabled) {
        @media (hover: hover) and (pointer: fine) {
          opacity: 0.8;
        }
      }
    `};

  ${(props) =>
    props.theme === 'primary-dark-bg' &&
    css`
      background-color: ${variables.colors.white};
      color: black;
      mix-blend-mode: lighten;

      ${!props.isSubmitting &&
      css`
        &:disabled {
          opacity: 0.8;
        }
      `}
      &:hover:not(:disabled) {
        @media (hover: hover) and (pointer: fine) {
          opacity: 0.8;
        }
      }
    `};

  ${(props) =>
    props.theme === 'secondary' &&
    css`
      background-color: ${variables.colors.lightGray};
      border-color: transparent;
      color: ${variables.colors.lighterBlack};
      transition: background-color 0.2s linear;

      &:disabled {
        color: ${variables.colors.darkerGray};
      }

      &:hover:not(:disabled) {
        @media (hover: hover) and (pointer: fine) {
          background-color: ${variables.colors.mediumGray};
        }
      }
    `}

  ${(props) =>
    props.theme === 'secondary-light-bg' &&
    css`
      background-color: transparent;
      border-color: ${variables.colors.lighterBlack};
      color: ${variables.colors.lighterBlack};

      ${!props.isSubmitting &&
      css`
        &:disabled {
          border-color: ${variables.colors.darkerGray};
          color: ${variables.colors.darkerGray};
        }
      `}
      &:hover:not(:disabled) {
        @media (hover: hover) and (pointer: fine) {
          background-color: ${rgba(variables.colors.white, 0.3)};
        }
      }
    `};

  ${(props) =>
    props.theme === 'tile' &&
    css`
      background-color: ${variables.colors.white};
      border-color: ${variables.colors.darkGray};
      color: ${variables.colors.lighterBlack};

      ${!props.isSubmitting &&
      css`
        &:disabled {
          color: ${variables.colors.darkGray};
        }
      `}
      &:hover:not(:disabled) {
        @media (hover: hover) and (pointer: fine) {
          background-color: ${variables.colors.lighterGray};
        }
      }

      ${(props: ButtonProps) =>
        props.isSelected &&
        css`
          border-color: ${variables.colors.highlight};
        `}
    `};

  ${(props) =>
    props.theme === 'transparent' &&
    css`
      background: transparent;
      border-color: transparent;
      height: auto;
      min-width: 0;
      padding: 0;
    `}
`;

export const LinkButtonThemesStyles = css<ButtonProps>`
  ${(props) =>
    props.theme === 'link' &&
    css`
      color: ${variables.colors.highlight};

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: ${variables.colors.highlightHover};
        }

        // Override icon hover color
        &:hover span {
          color: ${variables.colors.highlightHover} !important;
        }

        &:focus {
          outline: ${variables.colors.highlight} solid ${rem(2)};
          outline-offset: ${rem(2)};
        }
      }
    `}

  ${(props) =>
    props.theme === 'link-black' &&
    css`
      color: ${variables.colors.lighterBlack};

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          opacity: 0.8;
        }
      }
    `}

  ${(props) =>
    props.theme === 'link-menu' &&
    css`
      color: ${variables.colors.lighterBlack};

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: ${variables.colors.primary} !important;
        }
      }
    `}
`;
