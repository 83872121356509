import { AxfoodApi, OrderDataV2 } from '@occ/api-client';

const api = new AxfoodApi({
  baseUrl: '',
});

export const cancelOrder = (orderID: string) => {
  return api.cancelOrderUsingPost(orderID);
};

export interface OrderDataV2WithOrderNumber extends OrderDataV2 {
  orderNumber: string;
}

export const getOrder = async (orderId: string) => {
  const response = await api.getFullOrderDataUsingGet({ q: orderId });
  return response.data as OrderDataV2WithOrderNumber;
};

export const getOrderStatus = async (orderID: string) => {
  const response = await api.validateOrderEntriesUsingGet(orderID);
  return response.data;
};

export const getComplaintOrders = async () => {
  const response = await api.getOrdersUsingGet();
  return response.data;
};

export const isNearLockers = async (storeId: string, latitude: number, longitude: number) => {
  return await api.checkIfPointIsCloseToStoreUsingGet(storeId, { latitude, longitude });
};

export const startChangingOrder = (orderID?: string) => api.continueShopForOrderUsingPost({ orderNumber: orderID! });

export const getOrderProcess = (orderID: string) => api.isOrderProcessFinishedUsingGet(orderID);

export const startCollectOrderProcess = async (orderCode: string) => {
  const response = await api.startCollectProcessUsingPost(orderCode, { format: 'json' });
  return response.data;
};

export const unlockOrderBox = async (
  orderCode: string,
  boxId: string,
  previousOpened: {
    previousOpened: string;
  }
) => {
  const response = await api.unlockOrderBoxUsingPost(orderCode, boxId, previousOpened, { format: 'json' });
  return response.data;
};

export const finishCollectProcess = async (orderCode: string) => {
  const response = await api.finishCollectProcessUsingPost(orderCode, { format: 'json' });
  return response.data;
};
