import { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import { SizeTypes, ThemeTypes } from '@molecules/AddToCart/AddToCart';
import Config from '@config';

export const ShowHideAnimation = css<{ size: SizeTypes; hide: boolean }>`
  ${(props) =>
    props.size === 'xsmall' &&
    css`
      opacity: 1;
      transition: opacity 300ms;
      ${props.hide &&
      css`
        opacity: 0;
      `}
    `}
`;

export const CommonButtonStyle = css<{
  hasItems: boolean;
  isActive: boolean;
  theme: ThemeTypes;
  size: SizeTypes;
  hide: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0 none;
  border-radius: ${rem(99)};
  background-color: ${variables.colors.lightGray};
  color: ${variables.colors.darkGray};
  height: ${rem(28)};
  width: ${rem(28)};

  ${ShowHideAnimation};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    ${(props) =>
      props.size !== 'small' &&
      css`
        height: ${rem(32)};
        width: ${rem(32)};
      `}
  }

  &:not(:first-child) {
    background-color: ${variables.colors.primary};
  }

  &:disabled {
    &:not(:first-child) {
      background-color: ${variables.colors.primaryInactive};
    }

    cursor: auto;
  }

  ${(props) =>
    props.isActive &&
    css`
      &:not(:first-child) {
        color: ${variables.colors.white};
      }
    `}
`;
export const PrimaryButton = css<{
  hasItems: boolean;
  isActive: boolean;
  theme: ThemeTypes;
  size: SizeTypes;
  hide: boolean;
}>`
  ${CommonButtonStyle};
  font-size: ${rem(15)};
  height: ${rem(36)};
  width: ${rem(36)};

  ${(props) =>
    props.hasItems &&
    css`
      background-color: ${variables.colors.primary};
      color: ${variables.colors.white};

      &:disabled {
        background-color: ${variables.colors.primaryInactive};
      }

      ${props.isActive &&
      css`
        &:disabled {
          background-color: ${variables.colors.primary};
        }
      `}
    `}
`;

export const SecondaryButton = css<{
  hasItems: boolean;
  isActive: boolean;
  theme: ThemeTypes;
  size: SizeTypes;
  hide: boolean;
}>`
  ${CommonButtonStyle};
  font-size: ${rem(11)};
  height: ${rem(28)};
  width: ${rem(28)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    ${(props) =>
      props.size !== 'small' &&
      css`
        font-size: ${rem(13)};
        height: ${rem(32)};
        width: ${rem(32)};
      `}
  }

  ${(props) =>
    props.isActive &&
    css`
      &:not(:first-child) {
        color: ${variables.colors.white};
      }

      ${props.hasItems &&
      css`
        &:disabled {
          &:not(:first-child) {
            background-color: ${variables.colors.primary};
          }
        }
      `}
    `}
`;

export const WishListButton = css<{
  hasItems: boolean;
  isActive: boolean;
  theme: ThemeTypes;
  size: SizeTypes;
  hide: boolean;
}>`
  ${CommonButtonStyle};
  color: ${variables.colors.lighterBlack};

  &:not(:first-child) {
    background-color: ${variables.colors.lightGray};
  }

  &:disabled {
    &:not(:first-child) {
      background-color: ${variables.colors.cherryRed};
    }
  }

  ${(props) =>
    props.isActive &&
    css`
      &:not(:first-child) {
        color: ${variables.colors.lighterBlack};
      }
    `}
`;
