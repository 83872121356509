import variables from '@styles/variables';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: ${rem(13)};
  font-family: ${variables.fonts.fontGothamBook};

  td {
    padding: ${rem(8)} ${rem(16)};

    &:last-child {
      text-align: right;
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: ${variables.colors.lighterGray};
      }
    }
  }
`;
