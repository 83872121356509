import { AxfoodApi } from '@occ/api-client';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { setOrderCloseTimePassedModalVisibility } from '@slices/modalSlice';
import Config from '@config';
import debounce from '@helpers/debounce';
import { useEffect } from 'react';
import paths from '@constants/paths';
import useUserAgent from '@hooks/useUserAgent';
import webToAppApi from '@api/web-to-app';
import useCustomRouter from '@hooks/useCustomRouter';

const originalFetch: typeof fetch = global.fetch;

const axfoodApi = new AxfoodApi({
  baseUrl: '',
});

const isOwnDomain = (hostname: string) => {
  const ownDomains = ['localhost', 'axfood.se', 'local.se', 'willys.se'];
  return ownDomains.some((domain) => hostname === domain || hostname.endsWith('.' + domain));
};

let csrfToken: string;

function useGlobalFetchInterceptor() {
  const router = useCustomRouter();
  const dispatch = useAppDispatch();
  const { isNativeApp } = useUserAgent();

  const renewAuth = () => {
    if (isNativeApp) {
      webToAppApi.actionRenewAccessToken();
    } else if (router.pathname !== paths.USER_LOGIN) {
      router.push(
        {
          pathname: router.pathname,
          query: { ...router.query, from: router.pathname, loginConfirm: true },
        },
        { pathname: paths.USER_LOGIN },
        { shallow: true }
      );
    }
  };

  const debouncedRenewAuth = debounce(renewAuth, Config.TIMEOUT.axiosInterceptorRedirectToLoginConfirmMs);

  useEffect(() => {
    window.fetch = async function (input: RequestInfo | URL, init?: RequestInit) {
      const url =
        // @ts-ignore
        typeof input === 'string' ? new URL(input, window.location.origin) : new URL(input.url, window.location.origin);
      if (!isOwnDomain(url.hostname)) {
        return originalFetch(input, init);
      }
      let [resource, options] = [input, init];

      // Conditionally add csrf token header
      if (csrfToken) {
        options = {
          ...options,
          headers: {
            ...options?.headers,
            'X-CSRF-Token': csrfToken,
          },
        };
      }

      const response = await originalFetch(resource, options);
      if (response.status === 401) {
        const data = await response.json();
        if (data && data.error === 'csrf.badormissing') {
          const csrfResponse = await axfoodApi.getCsrfTokenUsingGet();
          csrfToken = await csrfResponse.json();
          return fetch(input, options);
        }
      }

      if (response.status === 402) {
        // expected placeOrder
        return response;
      }

      if (!response.ok) {
        const data = await response.json();
        if (data.errorCode === 'order.openForEdit.orderCloseTimePassed') {
          dispatch(setOrderCloseTimePassedModalVisibility(true));
        }
        if (data.customerLoggedIn && data.customerLoggedIn !== 'false') {
          debouncedRenewAuth();
        }
      }
      return response;
    };
  }, [debouncedRenewAuth, dispatch]);
}

export default useGlobalFetchInterceptor;
