import React, { ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent, RefObject, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Icon from '@atoms/Icon/Icon';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectCartIsUpdating } from '@slices/cartUpdatingSlice';
import {
  StyledAddToCart,
  StyledAddToCartButton,
  StyledAddToCartButtonConfirm,
  StyledAddToCartBuyButton,
  StyledAddToCartInputField,
  StyledInputFieldWrapper,
  StyledOutOfStock,
} from './AddToCart.styles';
import Config from '@config';
import PlusSmall from '@icons/plus_small.svg';
import MinusSmall from '@icons/minus_small.svg';
import IconTooltip from '@molecules/IconTooltip/IconTooltip';
import Info from '@icons/info.svg';

export type ThemeTypes = 'primary' | 'secondary' | 'wishlist';
export type SizeTypes = 'large' | 'medium' | 'small' | 'xsmall';

interface Props {
  inputRef: RefObject<HTMLInputElement>;
  onBlurHandler: (e: FocusEvent<HTMLInputElement>) => void;
  onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocusHandler: (e: FocusEvent<HTMLInputElement>) => void;
  onKeyUpHandler?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyDownHandler?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onClickHandler: (number: number, e?: MouseEvent<HTMLButtonElement>) => void;
  currentQuantity: number;
  isActive: boolean;
  incrementValue?: number;
  unit?: string;
  minAmount?: number;
  maxAmount?: number;
  outOfStock?: boolean;
  outOfStockLabel?: string;
  theme?: ThemeTypes;
  size?: SizeTypes;
  overrideHidingElements?: boolean;
  showBuyButtonForZeroQuantity?: boolean;
  ignoreCartIsUpdating?: boolean;
  shouldEnableWhenOpenOrder?: boolean;
  preventIncrementDueToLowStock?: boolean;
}

const AddToCart = ({
  inputRef,
  onBlurHandler,
  onChangeHandler,
  onFocusHandler,
  onKeyUpHandler,
  onKeyDownHandler,
  onClickHandler,
  currentQuantity,
  isActive,
  incrementValue = 1,
  unit = 'pieces',
  minAmount = Config.PRODUCT.MIN_AMOUNT,
  maxAmount = Config.PRODUCT.MAX_AMOUNT,
  outOfStock,
  outOfStockLabel,
  theme = 'primary',
  size = 'large',
  overrideHidingElements = false,
  showBuyButtonForZeroQuantity = false,
  ignoreCartIsUpdating = false,
  shouldEnableWhenOpenOrder = false,
  preventIncrementDueToLowStock = false,
}: Props) => {
  const { t } = useTranslation('productCard');
  let cartIsUpdating = useAppSelector(selectCartIsUpdating);
  if (ignoreCartIsUpdating) cartIsUpdating = false;
  const animateAndHideElements = size === 'xsmall' && currentQuantity <= 0 && !overrideHidingElements;

  const increaseTo = +parseFloat(String(currentQuantity + incrementValue)).toFixed(2);
  const decreaseTo = +parseFloat(String(currentQuantity - incrementValue)).toFixed(2);

  const incrementDisabled = (cartIsUpdating || currentQuantity >= maxAmount) && !isActive;
  return (
    <>
      {outOfStock && !shouldEnableWhenOpenOrder ? (
        <StyledOutOfStock theme={theme}>{outOfStockLabel || t('quantityInput->outOfStock')}</StyledOutOfStock>
      ) : (
        <StyledAddToCart disabled={cartIsUpdating} hasItems={currentQuantity > 0} theme={theme} size={size}>
          {showBuyButtonForZeroQuantity && currentQuantity === 0 ? (
            <StyledAddToCartBuyButton
              theme="primary"
              size="xsmall"
              onClick={(e) => onClickHandler(+parseFloat(String(currentQuantity + incrementValue)).toFixed(2), e)}
            >
              {t('quantityInput->buyButtonText')}
            </StyledAddToCartBuyButton>
          ) : (
            <>
              <StyledAddToCartButton
                isActive={isActive}
                hasItems={currentQuantity > 0}
                title={t('quantityInput->decrease')}
                aria-label={`${t('quantityInput->decrease')} till ${decreaseTo} ${
                  unit ? t(`common:units->${unit}`) : t('common:units->pieces')
                }`}
                type="button"
                onClick={(e) => onClickHandler(decreaseTo, e)}
                disabled={cartIsUpdating || currentQuantity - incrementValue < minAmount || animateAndHideElements}
                aria-disabled={cartIsUpdating || currentQuantity - incrementValue < minAmount || animateAndHideElements}
                theme={theme}
                size={size}
                hide={animateAndHideElements}
              >
                <Icon
                  svg={MinusSmall}
                  size={theme === 'primary' ? 16 : 12}
                  color={
                    theme === 'primary' && (currentQuantity > 0 || (isActive && currentQuantity > 0))
                      ? 'white'
                      : theme === 'wishlist'
                      ? 'lighterBlack'
                      : currentQuantity - incrementValue < minAmount
                      ? 'darkGray'
                      : 'lighterBlack'
                  }
                  data-testid="icon-minus"
                />
              </StyledAddToCartButton>

              <StyledInputFieldWrapper size={size} hide={animateAndHideElements}>
                <StyledAddToCartInputField
                  ref={inputRef}
                  noValidate
                  name="quantity"
                  disabled={cartIsUpdating || animateAndHideElements}
                  hasItems={currentQuantity > 0}
                  isActive={isActive}
                  onBlur={onBlurHandler}
                  onChange={onChangeHandler}
                  onFocus={onFocusHandler}
                  onKeyUp={onKeyUpHandler}
                  onKeyDown={onKeyDownHandler}
                  value={`${currentQuantity.toString()} ${
                    unit ? t(`common:units->${unit}`) : t('common:units->pieces')
                  }`}
                  aria-label={t('quantityInput->description')}
                  role="status"
                  type="text"
                  theme={theme}
                  size={size}
                />
              </StyledInputFieldWrapper>

              {preventIncrementDueToLowStock ? (
                <IconTooltip
                  content={t('quantityInput->incrementDisabledDueToLowStock')}
                  iconProps={{ svg: Info, size: 16 }}
                  align="center"
                  position="bottom"
                  color="black"
                  openOnClick
                  variant="small"
                  disableAutoClose
                />
              ) : (
                <StyledAddToCartButton
                  title={t('quantityInput->increase')}
                  aria-label={`${t('quantityInput->increase')} till ${increaseTo} ${
                    unit ? t(`common:units->${unit}`) : t('common:units->pieces')
                  }`}
                  type="button"
                  isActive={isActive}
                  hasItems={currentQuantity > 0}
                  onClick={(e) => onClickHandler(increaseTo, e)}
                  disabled={incrementDisabled}
                  aria-disabled={incrementDisabled}
                  theme={theme}
                  size={size}
                  hide={false}
                >
                  {isActive ? (
                    <StyledAddToCartButtonConfirm>{t('quantityInput->ok')}</StyledAddToCartButtonConfirm>
                  ) : (
                    <Icon
                      svg={PlusSmall}
                      size={theme === 'primary' ? 16 : 12}
                      color={theme === 'wishlist' ? 'lighterBlack' : 'white'}
                      data-testid="icon-plus"
                    />
                  )}
                </StyledAddToCartButton>
              )}
            </>
          )}
        </StyledAddToCart>
      )}
    </>
  );
};

export default AddToCart;
