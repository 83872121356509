module.exports = {
  locales: ['sv'],
  defaultLocale: 'sv',
  // https://github.com/aralroca/next-translate/issues/703
  extensionsRgx: /\.page\.(tsx|ts|js|mjs|jsx)$/,
  keySeparator: '->',
  pages: {
    '*': [
      'common',
      'metadata',
      'footer',
      'search',
      'menubutton',
      'sidenav',
      'error',
      'login',
      'tooltip',
      'productCard',
      'product',
      'slotSelector',
      'timeSlotDetails',
      'timeSlots',
      'cartConflictModal',
      'conflictListItem',
      'openOrderNotice',
      'modal',
      'order',
      'addToList',
      'deliverypicker',
      'lostPassword',
      'createAccount',
      'bankid',
      'cartpreview',
      'loginHint',
      'productDetailsRelated',
      'breadcrumbs',
      'expandableContainer',
      'loadMore',
      'account',
      'buyAllProductsComponent',
      'orderCloseTimePassedModal',
      'addToList',
    ],
    '/': ['checkoutEmptyCartModal', 'sidenav', 'startPage', 'personalElements'],
    'rgx:^/erbjudanden': ['promotionPage', 'productFilter', 'searchresultpage', 'vouchercard'],
    'rgx:^/butiker': ['storeListPage'],
    'rgx:^/registrera': ['createAccount'],
    '/artikel/[...articleName]': ['addToCart', 'contactUsForm', 'multiSearch', 'newsComponent'],
    '/butik/[...storeParameters]': ['storePage'],
    '/store-page': ['storePage'],
    '/butik-sok': ['storefinder', 'infowindow'],
    'rgx:^/mina-listor': ['wishlist'],
    '/delad-lista': ['wishlist'],
    '/varukorg': ['cart', 'cartpage'],
    '/kassa': ['deliverypicker', 'checkoutpage', 'paymentModes', 'cart', 'vouchers', 'checkoutEmptyCartModal'],
    'rgx:^/kassa/bekraftelse': ['cart'],
    '/mina-kop/detalj': ['cart', 'table'],
    '/min-order': ['cart'],
    '/minavanligastevaror': ['productFilter'],
    'rgx:^/sortiment': ['categorypage', 'productFilter'],
    '/mitt-konto': ['account', 'editablerow'],
    '/mitt-konto/ta-bort': ['contactUsForm'],
    '/orderdeliverystatus': ['orderdeliverystatus'],
    '/sok': ['searchresultpage', 'productFilter'],
    '/hamta': ['collectOrderPage', 'orderdeliverystatus'],
  },
  logBuild: process.env.NODE_ENV !== 'production',
};
