import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import variables from '@styles/variables';

type ColorValueType = { white: string; black: string; red: string; gray: string; darkRed: string };

const colors: ColorValueType = {
  red: variables.colors.primary,
  white: variables.colors.white,
  black: variables.colors.lighterBlack,
  gray: variables.colors.darkerGray,
  darkRed: variables.colors.primaryDark,
};

export interface HeadingProps {
  size?: string;
  color?: HeadingColors;
  isLabel?: boolean;
}

export const headingBaseStyles = css`
  font-family: ${variables.fonts.fontGothamSerifBold};
  margin: 0;
  padding: 0;
`;

const labelStyles = css`
  font-family: ${variables.fonts.fontGothamMedium};
  text-transform: uppercase;
  font-size: ${rem(13)};
  font-weight: 500;
  white-space: nowrap;
`;

export const H1Styles = (props?: HeadingProps) => css`
  ${headingBaseStyles};
  color: ${props?.color && colors[props.color]};
  font-size: ${props?.size === 'large' ? rem(32) : rem(30)};
  line-height: ${rem(36)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    font-size: ${props?.size === 'large' ? rem(40) : rem(30)};
    line-height: ${props?.size === 'large' ? rem(44) : rem(36)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    font-size: ${props?.size === 'large' ? rem(60) : rem(32)};
    line-height: ${props?.size === 'large' ? rem(64) : rem(36)};
  }

  ${props?.isLabel && labelStyles};
`;

export const StyledH1 = styled.h1<HeadingProps>`
  ${(props) => H1Styles(props)}
`;

export const H2Styles = (props?: HeadingProps) => css`
  ${headingBaseStyles};
  color: ${props?.color && colors[props.color]};
  font-size: ${props?.size === 'small' ? rem(22) : rem(30)};
  line-height: ${props?.size === 'small' ? rem(28) : rem(36)};

  ${Config.MEDIA.FROM_DESKTOP} {
    font-size: ${props?.size === 'small' ? rem(22) : rem(32)};
    line-height: ${props?.size === 'small' ? rem(28) : rem(36)};
  }

  ${props?.isLabel && labelStyles};
`;

export const StyledH2 = styled.h2<HeadingProps>`
  ${(props) => H2Styles(props)}
`;

export const H3Styles = (props?: HeadingProps) => css`
  ${headingBaseStyles};
  color: ${props?.color && colors[props.color]};
  font-size: ${rem(20)};
  line-height: ${rem(28)};

  ${props?.isLabel && labelStyles};
`;

export const StyledH3 = styled.h3<HeadingProps>`
  ${(props) => H3Styles(props)}
`;
