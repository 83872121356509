import useSWR from 'swr';
import { type AxfoodBasicProductViewModel, TwoTilesApi } from '@occ/api-client';
import useCustomer from '@hooks/useCustomer';
import useStore from '@hooks/useStore';

const api = new TwoTilesApi({
  baseUrl: '',
});

const fetcher = async (componentId: string) => {
  const response = await api.getProductsForProductBannerUsingGet2(componentId);
  // swagger response type incorrect - typecast
  return response.data as unknown as {
    products?: Array<AxfoodBasicProductViewModel>;
    componentNotFound?: string;
  };
};

const useTwoTilesProducts = (componentId: string, show: boolean) => {
  const { store } = useStore();
  const { customer } = useCustomer();
  const storeId = store?.storeId;
  const shouldFetch = show && Boolean(customer);

  const key = {
    endpoint: '/twoTilesButtonAndProductBannerComponent',
    componentId,
    customerId: customer?.uid,
    storeId,
    // TODO: Do we need to add slotCode to key?
  };

  const { data, isLoading } = useSWR(shouldFetch ? key : null, ({ componentId }) => fetcher(componentId));

  return {
    showSpinner: isLoading,
    products: data?.products ?? [],
  };
};

export default useTwoTilesProducts;
