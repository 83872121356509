export default {
  HOME: '/',
  CART: '/varukorg',
  CHECKOUT: '/kassa',
  REGISTER: '/registrera',
  REGISTER_PRIVATE: '/registrera/privat',
  REGISTER_PRIVATE_CREATE: '/registrera/privat/skapa',
  REGISTER_B2C_IDENTIFY: '/registrera/privat/identifiera',
  REGISTER_B2B_IDENTIFY: '/registrera/foretag/identifiera',
  REGISTER_B2B_CREATE: '/registrera/foretag/skapa',
  REGISTER_B2C_CONFIRMATION: '/registrera/privat/valkommen',
  REGISTER_B2B_CONFIRMATION: '/registrera/foretag/valkommen',
  USER: '/anvandare',
  USER_LOGIN: '/anvandare/inloggning',
  B2B_LOGIN: '/anvandare/inloggning/foretag',
  ACCOUNT_ORDERS_OLD: '/mitt-konto/ordrar',
  MY_ACCOUNT: '/mitt-konto',
  REMOVE_ACCOUNT: '/mitt-konto/ta-bort',
  ACCOUNT_ORDERS: '/mina-kop',
  ACCOUNT_ORDER_DETAILS: '/mina-kop/[orderId]',
  MY_LISTS: '/mina-listor',
  SHARED_LIST: '/delad-lista',
  OFFERS: '/erbjudanden',
  OFFERS_STORE: '/erbjudanden/butik',
  OFFERS_ECOMMERCE: '/erbjudanden/ehandel',
  PDP: '/produkt',
  PDP_OFFLINE: '/erbjudanden/offline',
  PDP_ONLINE: '/erbjudanden/online',
  PDP_PREVIEW: '/erbjudanden/preview',
  SEARCH: '/sok',
  CATEGORY: '/sortiment',
  STORE_SEARCH: '/butik-sok',
  PICKUP_STORES: '/butiker/hamta',
  STORE_PAGE: '/butik',
  APP_STORE_PAGE: '/store-page',
  MY_COMMON_ITEMS: '/minavanligastevaror',
  ALL_STORES: '/butiker/alla',
  ANONYMOUS_ORDER_DETAILS: '/min-order',
  LOST_PASSWORD: '/anvandare/glomt-losenord',
  LOST_PASSWORD_B2B: '/anvandare/glomt-losenord/foretag',
  RESET_PASSWORD: '/anvandare/aterstall-losenord-fran-email',
  RESET_PASSWORD_B2B: '/anvandare/aterstall-losenord-fran-email-b2b',
  NEWS_DETAIL: '/om-oss/nyheter-och-press/nyhet',
  TRACKING: '/o/',
  ORDER_STATUS_PAGE: '/hamta/',
  ARTICLE: {
    PERSONALIZATION: '/artikel/personifiering',
    NEWSDETAIL: '/artikel/om-oss/nyheter-och-press/nyhet',
    NEWSPAGE: '/artikel/om-oss/nyheter-och-press',
    FAQPAGE: 'artikel/kundservice/vanliga-fragor-och-svar',
    GOOD_ENVIRONMENT: '/artikel/om-oss/hallbarhet/bra-miljoval',
  },
  external: {
    PAYEX: 'http://www.payex.se',
    WILLYS: 'http://www.willys.se',
    MPA: 'https://www.lakemedelsverket.se/e-handlare#Z00257',
    ATLASSIAN_GUIDELINES:
      'https://axfood.atlassian.net/wiki/spaces/DA/pages/1252392989/Anv+ndbarhet+och+tillg+nglighet+Guidelines',
    ECOMMERCE_TERMS: '/artikel/kundservice/villkor-e-handel',
    ECOMMERCE_TERMS_PLUS: '/artikel/kundservice/villkor-for-willys-plus',
    ECOMMERCE_CONTACT: '/artikel/kundservice/kontakta-oss',
    INTEGRITY_POLICY: '/artikel/dataskydd',
    DIGITAL_RECEIPT_ARTICLE: '/artikel/digitalakvitton',
    B2B_TERMS: '/artikel/avtalforetag',
    B2B_INFO_PAGE: '/artikel/foretag',
    PERSONAL_OFFERS: '/artikel/personifiering',
    FACEBOOK: 'https://www.facebook.com/Willys/',
    INSTAGRAM: 'https://www.instagram.com/willyssverige/',
    TIKTOK: 'https://www.tiktok.com/@willyssverige',
    YOUTUBE: 'https://www.youtube.com/c/WillysAB',
    LINKEDIN: 'https://www.linkedin.com/company/willys-axfood/',
  },
};

export enum LoginQueryParams {
  MY_LISTS = 'MY_LISTS',
  MY_COMMON_ITEMS = 'MY_COMMON_ITEMS',
  MY_ACCOUNT = 'MY_ACCOUNT',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  ACCOUNT_ORDERS = 'ACCOUNT_ORDERS',
  CART = 'CART',
  CART_PAGE = 'CART_PAGE',
  CHECKOUT = 'CHECKOUT',
  CHECKOUT_PAGE = 'CHECKOUT_PAGE',
  LOGIN_CONFIRM_SUCCESS = 'LOGIN_CONFIRM_SUCCESS',
}
