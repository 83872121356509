import { AxfoodApi } from '@occ/api-client';

const axfoodApi = new AxfoodApi({
  baseUrl: '',
});

export const getAvailableStores = async (online = false, clickAndCollect?: boolean, b2bClickAndCollect?: boolean) => {
  const response = await axfoodApi.getStoresUsingGet({
    online,
    clickAndCollect,
    b2bClickAndCollect,
  });
  return response.data;
};

export const getActiveStore = async () => {
  const response = await axfoodApi.getCurrentBaseStoreUsingGet();
  return response.data;
};

export const activateStore = (
  storeId: string,
  activelySelected: boolean,
  forceAsPickingStore: boolean,
  signal: AbortSignal
) =>
  axfoodApi.activateStoreInSessionUsingPost(
    {
      storeId,
      activelySelected,
      forceAsPickingStore,
    },
    { signal }
  );

export const getSuggestedPickupStore = (defaultToBaseStore?: boolean) =>
  axfoodApi.getSuggestedPickUpStoreUsingGet({
    defaultToBaseStore,
  });

export const checkIfStoreIsNotPermanentlyClosed = (storeId: string) =>
  axfoodApi.checkIfStoreIsNotPermanentlyClosedUsingGet({
    storeId,
  });

export const searchStores = async (
  q: string,
  sort: string,
  size?: number,
  externalPickupLocation?: boolean,
  isB2B?: boolean,
  searchHasClickAndCollect?: boolean
) => {
  const result = await axfoodApi.getStoreUsingGet(
    {
      q,
      sort,
      size,
      externalPickupLocation,
      searchHasClickAndCollect: searchHasClickAndCollect && !isB2B ? true : undefined,
      searchHasB2bClickAndCollect: searchHasClickAndCollect && isB2B ? true : undefined,
    },
    { format: 'json' }
  );
  return result.data as StoreSearchResponseType;
};

export const getStoreInformation = async (storeId: string) => {
  const result = await axfoodApi.getStoreInformationUsingGet1(storeId, { format: 'json' });
  return result.data;
};

export const getStoreInformationSSR = async (storeId: string, cookie: string) => {
  const ssrApi = new AxfoodApi({
    baseUrl: global.process.env.API_URL,
    baseApiParams: {
      headers: {
        'x-api-key': global.process.env.X_API_KEY || '',
      },
    },
    // TODO: This is the only SSR request without logging.
    // Looking into a long-term logging solution for fetch requests
    // This looks promising - https://www.npmjs.com/package/next-logger
    // customFetch: fetchWithLogging,
  });

  const result = await ssrApi.getStoreInformationUsingGet1(storeId, {
    headers: {
      cookie,
    },
    format: 'json',
  });
  return result.data;
};
