import styled from 'styled-components';
import Table from '@atoms/Table/Table';
import { rem } from 'polished';
import Text from '@atoms/Text/Text';

export const StyledHeaderText = styled(Text)`
  margin-bottom: ${rem(12)};
`;
export const StyledDRITable = styled(Table)`
  margin-bottom: ${rem(20)};
`;
