import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import variables from '@styles/variables';
import Text from '@atoms/Text/Text';

const BaseToolbarStyle = css<{
  hidden: boolean;
  sticky: boolean;
  headerHeight?: number;
}>`
  position: relative;
  height: ${rem(60)};
  padding: ${rem(8)} ${rem(20)};
  background-color: ${variables.colors.lightGray};
  display: flex;
  align-items: center;
  top: 0;
  gap: ${rem(20)};

  z-index: ${variables.zIndex.toolbar};
  transition: margin-top ${variables.animation.layout} ease;

  ${(props) =>
    props.sticky &&
    css`
      position: sticky;

      ${Config.MEDIA.BELOW_DESKTOP} {
        top: ${rem(props.headerHeight || variables.size.toolbarHeight)};
      }
    `}
  ${(props) =>
    props.hidden &&
    css`
      transform: translateY(-100%);
    `}
`;

export const StyledToolbar = styled.div<{
  hidden: boolean;
  sticky: boolean;
  usabillaFix: boolean;
  adjustUp?: boolean | number;
  adjustZIndex?: boolean;
  headerHeight?: number;
  adjustForSAYT?: boolean;
}>`
  ${BaseToolbarStyle};

  ${(props) =>
    props.usabillaFix &&
    css`
      display: flex !important;
      position: relative !important;
    `}
  ${(props) =>
    props.adjustZIndex &&
    css`
      z-index: 31;
    `}
  ${(props) =>
    props.adjustUp &&
    typeof props.adjustUp === 'number' &&
    css`
      z-index: 31;
      margin-top: -${rem(props.adjustUp)};
    `}

  ${(props) =>
    props.adjustForSAYT &&
    css`
      padding: 0;
    `}
`;

export const StyledStickyDeliveryPicker = styled.div<{
  hidden: boolean;
  sticky: boolean;
  headerHeight?: number;
}>`
  ${BaseToolbarStyle};
  height: auto;
  min-height: ${rem(60)};
  box-shadow: 0 ${rem(23)} ${rem(18)} ${rem(-16)} rgba(000, 000, 000, 0.1);
  width: 100%;
  border-top: ${rem(1)} solid ${variables.colors.mediumGray};
  background-color: ${variables.colors.white};
  margin-top: ${rem(-60)};
  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
`;

export const StyledStickyDeliveryPickerContent = styled.div`
  flex: 1;
`;

export const StyledInlineText = styled(Text)`
  display: inline;

  ${Config.MEDIA.FROM_MOBILE} {
    display: block;
  }
`;
