import { RefObject, useEffect, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import { useAppDispatch, useAppSelector } from '@hooks/useAppDispatch';
import useDocumentScroll from '@helpers/useDocumentScroll';
import MenuButton from '@atoms/MenuButton/MenuButton';
import Search from '@organisms/Search/Search';
import useUsabillaOverride from '@hooks/useUsabillaOverride';
import Config from '@config';
import {
  StyledInlineText,
  StyledStickyDeliveryPicker,
  StyledStickyDeliveryPickerContent,
  StyledToolbar,
} from '@components/organisms/Toolbar/Toolbar.styles';
import { selectStickyToolbar, selectToolbarSearchIsExpanded, setStickyToolbar } from '@slices/toolbarSlice';
import SearchAsYouType from '@organisms/SearchAsYouType/SearchAsYouType';
import useFeatureToggle from '@hooks/useFeatureToggle';
import Variant from '@utility/ABTest/Variant';
import MiniCart from '@molecules/MiniCart/MiniCart';
import useResponsive from '@hooks/useResponsive';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import { selectDeliveryPicker } from '@slices/deliveryPickerSlice';
import SymplifyExperiment from '@utility/ABTest/Symplify/SymplifyExperiment';
import dynamic from 'next/dynamic';
import Text from '@atoms/Text/Text';
import Button from '@atoms/Button/Button';
import useStore from '@hooks/useStore';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import paths from '@constants/paths';
import useCart from '@hooks/useCart';
import { getStoreInformation } from '@api/interfaces/storeApi';
import { AxfoodStoreInfoViewModel } from '@occ/api-client';

interface Props {
  headerRef: RefObject<HTMLDivElement>;
}

const DynamicDeliveryPicker = dynamic(
  () => import('@organisms/DeliveryPicker/DeliveryPicker' /* webpackChunkName 'DeliveryPicker' */)
);

const Toolbar = ({ headerRef }: Props) => {
  const { isEnabled: searchAsYouTypeEnabled } = useFeatureToggle(Config.FEATURE_TOGGLES.searchAsYouType);
  const { store } = useStore();
  const { cart } = useCart();
  const router = useRouter();
  const { t } = useTranslation('promotionPage');
  const [selectedOfflineStore, setSelectedOfflineStore] = useState({} as AxfoodStoreInfoViewModel);

  const deliveryPicker = useAppSelector(selectDeliveryPicker);
  const [promotionTabsElement, setPromotionTabsElement] = useState<HTMLElement | undefined>(undefined);
  const [hideToolbar, setHideToolbar] = useState(false);
  const [showStickyPromotionTabs, setShowStickyPromotionTabs] = useState(false);
  const [aboveToolbarHeight, setAboveToolbarHeight] = useState(0);

  const downScrollDistanceRef = useRef(0);
  const upScrollDistanceRef = useRef(0);
  const toolbarRef = useRef<HTMLDivElement>(null);

  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const isSearchExpanded = useAppSelector(selectToolbarSearchIsExpanded);
  const stickyToolbar: boolean = useAppSelector(selectStickyToolbar);
  const [promotionStoreId, setPromotionStoreId] = useState(null);
  const { isMobile, fromDesktop } = useResponsive();
  const applyUsabillaFix = useUsabillaOverride();
  const dispatch = useAppDispatch();

  const scrollThreshold = 60;

  const getStoreAndSetLocalState = async (id: string) => {
    const store = await getStoreInformation(id);
    if (!store?.external) {
      setSelectedOfflineStore(store);
    }
  };

  const makeDeliveryPickerSticky = () => {
    if (!!promotionTabsElement && router.asPath.startsWith(paths.OFFERS)) {
      if (router.query.mode === Config.ROUTES.promotionpage.store && !selectedOfflineStore?.storeId) {
        setShowStickyPromotionTabs(false);
      } else {
        if (headerRef.current && !stickyToolbar) {
          setShowStickyPromotionTabs(
            headerRef.current?.getBoundingClientRect().bottom > promotionTabsElement?.getBoundingClientRect().bottom
          );
        } else {
          setShowStickyPromotionTabs(false);
        }
      }
    } else {
      setShowStickyPromotionTabs(false);
    }
  };

  useResizeObserver(headerRef, (entry) => {
    setAboveToolbarHeight(entry.contentRect.height);
  });

  useEffect(() => {
    window.addEventListener('localStorageChange', function (event: any) {
      if (event.detail.key === Config.LOCAL_STORAGE_SELECTORS.promotionStoreId) {
        setPromotionStoreId(event.detail.updatedValue);
      }
    });
  }, []);

  useEffect(() => {
    if (promotionStoreId) getStoreAndSetLocalState(promotionStoreId);
  }, [promotionStoreId]);

  useEffect(() => {
    if (router.asPath.startsWith(paths.OFFERS)) {
      const el = document.getElementById('promotionTabs');
      if (el) setPromotionTabsElement(el);
    }
  }, [router.asPath]);

  useEffect(() => {
    if (!isMobile) setShowStickyPromotionTabs(false);
  }, [isMobile]);

  useDocumentScroll((scrollData) => {
    if (!isMobile) return;
    const { previousScrollTop, currentScrollTop } = scrollData;
    const scrollDistance = Math.abs(previousScrollTop - currentScrollTop);
    const scrolledPastToolbar =
      currentScrollTop > aboveToolbarHeight + (toolbarRef.current ? toolbarRef.current.offsetHeight : 0);

    if (!scrolledPastToolbar) {
      setHideToolbar(false);
    }

    if (currentScrollTop > previousScrollTop) {
      // Scrolling down
      upScrollDistanceRef.current = 0;
      downScrollDistanceRef.current += scrollDistance;
      if (!hideToolbar && downScrollDistanceRef.current + scrollDistance > scrollThreshold) {
        setHideToolbar(scrolledPastToolbar && true);
      }
      dispatch(setStickyToolbar(false));
    } else {
      // Scrolling up
      if (scrolledPastToolbar) {
        dispatch(setStickyToolbar(true));
      }
      downScrollDistanceRef.current = 0;
      upScrollDistanceRef.current += scrollDistance;
      if (hideToolbar && upScrollDistanceRef.current + scrollDistance > scrollThreshold) {
        setHideToolbar(false);
      }
    }

    makeDeliveryPickerSticky();
  });

  return (
    <>
      <StyledToolbar
        hidden={isMobile && hideToolbar && !isSearchExpanded}
        sticky={(isMobile && stickyToolbar) || !isMobile}
        usabillaFix={applyUsabillaFix}
        adjustUp={isMobile && isSearchExpanded && !deliveryPicker.isOpen && headerRef.current?.clientHeight}
        adjustZIndex={isCartPreviewOpen && !isMobile}
        ref={toolbarRef}
        headerHeight={headerRef.current?.clientHeight}
        adjustForSAYT={isMobile && isSearchExpanded}
      >
        {(isMobile ? !isSearchExpanded : true) && <MenuButton />}

        {searchAsYouTypeEnabled ? (
          <SymplifyExperiment testName="SearchAsYouType" defaultVariant="Original">
            <Variant variantName="Original">
              <Search />
            </Variant>
            <Variant variantName="SAYT">
              <SearchAsYouType />
            </Variant>
          </SymplifyExperiment>
        ) : (
          <Search />
        )}

        {fromDesktop && <DynamicDeliveryPicker />}

        {fromDesktop && <MiniCart />}
      </StyledToolbar>

      {store &&
        !!promotionTabsElement &&
        (router.asPath.startsWith(paths.OFFERS_STORE) || router.asPath.startsWith(paths.OFFERS_ECOMMERCE)) && (
          <StyledStickyDeliveryPicker
            hidden={stickyToolbar || !showStickyPromotionTabs}
            sticky={showStickyPromotionTabs && !stickyToolbar}
            headerHeight={headerRef.current?.clientHeight}
            role="button"
            tabIndex={0}
          >
            <>
              <StyledStickyDeliveryPickerContent>
                <Text type="subhead">
                  {t(
                    `promotionPage:stickyDeliveryPicker->${
                      store.activelySelected || selectedOfflineStore?.storeId ? '' : 'default->'
                    }${router?.query?.mode}->text`
                  )}

                  <StyledInlineText
                    element="span"
                    type="body"
                    color={
                      router.query.mode === Config.ROUTES.promotionpage.ecommerce && !store.activelySelected
                        ? 'red'
                        : 'black'
                    }
                  >
                    {router.query.mode === Config.ROUTES.promotionpage.ecommerce &&
                      store.activelySelected &&
                      (cart?.deliveryModeCode === Config.HOME_DELIVERY
                        ? cart?.deliveryAddress?.formattedAddress
                        : store?.name)}
                    {router.query.mode === Config.ROUTES.promotionpage.store &&
                      selectedOfflineStore?.storeId &&
                      selectedOfflineStore.name}
                    {router.query.mode === Config.ROUTES.promotionpage.ecommerce &&
                      !store.activelySelected &&
                      t('promotionPage:stickyDeliveryPicker->deliveryNotSelected')}
                  </StyledInlineText>
                </Text>
              </StyledStickyDeliveryPickerContent>
              <Button theme="link" onClick={() => scrollTo({ top: 0, behavior: 'smooth' })}>
                {t('promotionPage:stickyDeliveryPicker->change')}
              </Button>
            </>
          </StyledStickyDeliveryPicker>
        )}
    </>
  );
};

export default Toolbar;
