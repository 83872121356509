import type {
  AxfoodBasicProductViewModel,
  AxfoodBasicWishListViewModel,
  AxfoodProductDetailsViewModel,
  WishlistEntryForm,
} from '@occ/api-client';
import paths, { LoginQueryParams } from '@constants/paths';
import React, { useEffect, useState } from 'react';
import Button from '@atoms/Button/Button';
import Modal from '@organisms/Modal/Modal';
import Heading from '@atoms/Heading/Heading';
import useWishlists from '@hooks/useWishlists';

import {
  StyledButton,
  StyledButtonWrapper,
  StyledIcon,
  StyledModalContentWrapper,
  StyledProductListItem,
  StyledText,
  StyledWishlistRow,
  StyledWishlistRowText,
  StyledWishlistWrapper,
  StyledWishlistWrapperTopSection,
} from './AddToListComponent.styles';
import Text from '@atoms/Text/Text';
import { addToUserWishlist, createNewWishList } from '@api/interfaces/wishlistApi';
import webToAppApi from '@api/web-to-app';
import useUserAgent from '@hooks/useUserAgent';
import useCustomRouter from '@hooks/useCustomRouter';
import Spinner from '@molecules/Spinner/Spinner';
import { FormMode } from '@organisms/AddToListDropdown/types';
import useTranslation from 'next-translate/useTranslation';
import useSnackBar from '@hooks/useSnackbar';
import CreateNewListForm from '@organisms/AddToListDropdown/CreateNewListForm';
import {
  trackRedirectToLoginFromAddToList,
  trackShowSaveToWishListModal,
} from '@helpers/analyticsHelpers/trackWishList';
import useCustomer from '@hooks/useCustomer';
import Validation from '@icons/validation.svg';
import List from '@icons/list.svg';
import useCart from '@hooks/useCart';

interface AddToListCallback {
  option: AxfoodBasicWishListViewModel;
  productEntries: Array<WishlistEntryForm>;
  tracking?: {
    parent: string;
  };
}

interface CreateNewListCallback {
  inputValue: string;
}

interface Props {
  product?: AxfoodProductDetailsViewModel | AxfoodBasicProductViewModel;
  productEntries: Array<WishlistEntryForm>;
  addToListCallback?: ({ option, productEntries, tracking }: AddToListCallback) => void;
  createNewListCallback?: ({ inputValue }: CreateNewListCallback) => void;
  onClose: () => void;
  isOpen: boolean;
  queryParamsIfNotLoggedIn?: {
    from: LoginQueryParams;
  };
  tracking?: {
    parent: string;
  };
}

const AddToListComponent = ({
  product,
  queryParamsIfNotLoggedIn = { from: LoginQueryParams.MY_LISTS },
  productEntries,
  tracking,
  addToListCallback,
  createNewListCallback,
  onClose,
  isOpen,
}: Props) => {
  const { t } = useTranslation('addToList');
  const setSnack = useSnackBar();
  const { customer } = useCustomer();
  const { wishlists, error: wishlistError, isLoading, refreshWishlists } = useWishlists(customer?.uid);
  const { cart } = useCart();
  const [formMode, setFormMode] = useState<FormMode>('ADD');
  const { isNativeApp } = useUserAgent();
  const router = useCustomRouter();
  const [selectedWishListRow, setSelectedWishListRow] = useState<AxfoodBasicWishListViewModel>(
    {} as AxfoodBasicWishListViewModel
  );
  const [quantity, setQuantity] = useState(productEntries[0]?.quantity ?? 0);
  const [isFetching, setIsFetching] = useState(false);
  const [isCreatingNewList, setIsCreatingNewList] = useState(false);

  const saveToList = async () => {
    try {
      setIsFetching(true);
      await addToUserWishlist(selectedWishListRow.id, productEntries);

      if (addToListCallback) {
        addToListCallback({ option: selectedWishListRow, productEntries });
      }

      setIsFetching(false);
      setFormMode('COMPLETE');
      onClose();
      setSnack({
        text: productEntries.length === 1 ? t('addToList->snackBar->text') : t('addToList->snackBar->textPlural'),
        icon: 'valid',
        link: `${paths.MY_LISTS}/${selectedWishListRow.id}`,
        linkText: t('addToList->snackBar->linkText'),
        onClick: isNativeApp
          ? () => {
              webToAppApi.actionRouteToSpecificList(selectedWishListRow.id);
            }
          : undefined,
      });
    } catch (err) {
      setSnack({
        text: t('addToList->error->onSave'),
        icon: null,
      });
    } finally {
      setIsFetching(false);
    }
  };

  const handleNotLoggedIn = () => {
    if (isNativeApp) {
      webToAppApi.actionRenewAccessToken();
    } else {
      router.push(
        { pathname: router.pathname, query: { ...router.query, ...queryParamsIfNotLoggedIn } },
        {
          pathname: paths.USER_LOGIN,
        },
        { shallow: true }
      );
    }

    if (tracking?.parent) {
      trackRedirectToLoginFromAddToList(tracking.parent);
    }

    onClose();
  };

  const onSaveToListModalOpen = async () => {
    await refreshWishlists();
    if (customer?.isAnonymous) {
      handleNotLoggedIn();
    }

    if (tracking?.parent) {
      trackShowSaveToWishListModal(tracking.parent);
    }
  };

  const onCreateNewListHandler = async (inputValue: string) => {
    setIsCreatingNewList(true);
    try {
      await createNewWishList({
        name: inputValue,
        entries: [],
      });

      await refreshWishlists();
      if (createNewListCallback) {
        createNewListCallback({ inputValue });
      }

      setFormMode('ADD');
    } catch (err) {
      setSnack({
        text: t('addToList->error->onCreate'),
        icon: null,
      });
    } finally {
      setIsCreatingNewList(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedWishListRow({} as AxfoodBasicWishListViewModel);
      setFormMode('ADD');
    }
    if (isOpen) {
      onSaveToListModalOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, cart?.products]);

  useEffect(() => {
    if (productEntries && productEntries.length > 0) {
      productEntries[0].quantity = quantity;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  useEffect(() => {
    if (wishlists && wishlists.length > 0) setSelectedWishListRow(wishlists[0]);
  }, [wishlists]);

  return (
    <>
      {isOpen && !wishlistError && (
        <Modal
          noPadding
          size="medium"
          hasCloseIcon
          closeOnEscape
          onClose={onClose}
          confirmDisabled={!selectedWishListRow.id}
          isSubmitting={isLoading}
          hasMinHeight
        >
          {isLoading && <Spinner />}
          {!isLoading && (
            <>
              <StyledModalContentWrapper>
                <Heading variant="h2" size="small">
                  {t('addToList->heading->unsaved')}
                </Heading>
                {product && (
                  <StyledProductListItem
                    clickable={false}
                    product={product}
                    quantity={quantity}
                    quantityCallback={(quantity) => {
                      setQuantity(quantity);
                    }}
                    variant="addToList"
                  />
                )}

                <StyledWishlistWrapper>
                  <StyledWishlistWrapperTopSection>
                    <StyledText type={'subtitle'} size="small">
                      {formMode === 'NEW' ? t('addToList->heading->new') : t('addToList->heading->choose')}
                    </StyledText>
                    {formMode !== 'NEW' && (
                      <Button theme="link" data-testid="create-new-list" onClick={() => setFormMode('NEW')}>
                        {t('addToList->heading->new')}
                      </Button>
                    )}
                  </StyledWishlistWrapperTopSection>
                  {formMode === 'NEW' && (
                    <CreateNewListForm onCreateNewList={onCreateNewListHandler} isSaving={isCreatingNewList} />
                  )}

                  {wishlists &&
                    wishlists.length > 0 &&
                    wishlists?.map((wishlist) => (
                      <StyledWishlistRow
                        data-testid="wishlist-option"
                        theme="transparent"
                        onClick={() => {
                          setSelectedWishListRow(wishlist);
                        }}
                        isSelected={selectedWishListRow.id === wishlist.id}
                        key={wishlist.id}
                      >
                        <StyledIcon
                          color={selectedWishListRow.id === wishlist.id ? 'highlight' : 'black'}
                          svg={selectedWishListRow.id === wishlist.id ? Validation : List}
                          size={24}
                        />
                        <StyledWishlistRowText type="subtitle">
                          {wishlist.name} ({wishlist.numberOfProducts})
                        </StyledWishlistRowText>
                      </StyledWishlistRow>
                    ))}
                  {wishlists && wishlists.length <= 0 && (
                    <Text type="body" size="medium">
                      {t('addToList->empty')}
                    </Text>
                  )}
                </StyledWishlistWrapper>
              </StyledModalContentWrapper>
              <StyledButtonWrapper>
                <StyledButton theme="secondary" onClick={onClose}>
                  {t('addToList->cancel')}
                </StyledButton>
                <StyledButton
                  theme="primary"
                  onClick={saveToList}
                  disabled={!selectedWishListRow.id || (product && !quantity)}
                  isSubmitting={isFetching}
                >
                  {t('addToList->saveToList')}
                </StyledButton>
              </StyledButtonWrapper>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default AddToListComponent;
